import React from 'react';
import PropTypes from 'prop-types';
import Head from '../../atoms/Head/Head';

/*
  {description, ogDescription, twDescription, lang, meta, title, ogTitle, twTitle, children, defaultMicroData = true, microDataType}
*/

function Seo(props) {
  const { lang = 'en', meta = [], description = '', children, ...rest } = props;

  const headProps = {
    lang,
    meta,
    description,
    ...rest
  };
  return (
    <>
      <Head {...headProps}>{children}</Head>
    </>
  );
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  showSiteName: PropTypes.bool
};

export default Seo;
